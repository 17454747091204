import React from 'react';
import PropTypes from 'prop-types';

import Footer from 'components/sections/footer/footer';

import 'styles/main.scss';

// eslint-disable-next-line react/display-name
const Layout = ({ children }) => {
  return (
    <>
      <main>{children}</main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
