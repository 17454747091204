import React from 'react';

import AppStore from 'assets/icons/app-store.svg';
import GooglePlay from 'assets/icons/google-play.svg';

import styles from './store-buttons.module.scss';

export const AppStoreButton = () => (
  <AppStore id="app-store-button" className={styles.storeButton} />
);

export const GooglePlayButton = () => (
  <GooglePlay id="google-play-button" className={styles.storeButton} />
);
