import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import Logo from 'components/ui/logo/logo';

import FacebookIcon from 'assets/icons/footer-facebook-icon.inline.svg';
import InstagramIcon from 'assets/icons/footer-instagram-icon.inline.svg';
import TwitterIcon from 'assets/icons/footer-twitter-icon.inline.svg';
import ToutubeIcon from 'assets/icons/footer-youtube-icon.inline.svg';

import {
  AppStoreButton,
  GooglePlayButton,
} from 'components/ui/store-buttons/store-buttons';

import styles from './footer.module.scss';

const Footer = () => {
  return (
    <div className={styles.footerWrapper}>
      <Container className={styles.footerContainer}>
        <Row>
          <Col lg={{ span: 12 }} xl={{ span: 2 }}>
            <div className={styles.logoWrapper}>
              <Logo variant="blue" />
            </div>
          </Col>
          <Col
            lg={{ span: 12 }}
            xl={{ span: 10 }}
            className={styles.contentWrapper}
          >
            <div className={styles.footerContent}>
              <div className={styles.footerItem}>
                <h5>Company</h5>
                <a href="#" className={styles.footerLink}>
                  About
                </a>
                <a href="#" className={styles.footerLink}>
                  Careers
                </a>
                <a href="#" className={styles.footerLink}>
                  Press
                </a>
                <a href="#" className={styles.footerLink}>
                  Blog
                </a>
                <a href="#" className={styles.footerLink}>
                  Affiliates
                </a>
              </div>
              <div className={styles.footerItem}>
                <h5>Community</h5>
                <a href="#" className={styles.footerLink}>
                  Go Premium
                </a>
                <a href="#" className={styles.footerLink}>
                  Team Plans
                </a>
                <a href="#" className={styles.footerLink}>
                  Refer a Friend
                </a>
                <a href="#" className={styles.footerLink}>
                  Gift Card
                </a>
                <a href="#" className={styles.footerLink}>
                  Scholarships
                </a>
                <a href="#" className={styles.footerLink}>
                  Free Classes
                </a>
              </div>
              <div className={styles.footerItem}>
                <h5>Teaching</h5>
                <a href="#" className={styles.footerLink}>
                  Become a Teacher
                </a>
                <a href="#" className={styles.footerLink}>
                  Teaching Academy
                </a>
                <a href="#" className={styles.footerLink}>
                  Teacher Handbook
                </a>
                <a href="#" className={styles.footerLink}>
                  Partnerships
                </a>
              </div>
              <div className={styles.footerItem}>
                <h5>Legal</h5>
                <a href="#" className={styles.footerLink}>
                  Help
                </a>
                <a href="#" className={styles.footerLink}>
                  Privacy Policy
                </a>
                <a href="#" className={styles.footerLink}>
                  Terms of Use
                </a>
              </div>
              <div
                className={[styles.footerSocialWrapper, styles.footerItem].join(
                  ' '
                )}
              >
                <div className={styles.footerSocialLinks}>
                  <a href="#">
                    <FacebookIcon className={styles.footerSocialLink} />
                  </a>
                  <a href="#">
                    <InstagramIcon className={styles.footerSocialLink} />
                  </a>
                  <a href="#">
                    <TwitterIcon className={styles.footerSocialLink} />
                  </a>
                  <a href="#">
                    <ToutubeIcon className={styles.footerSocialLink} />
                  </a>
                </div>
                <div className={styles.appSmaller}>
                  <a href="#">
                    <AppStoreButton />
                  </a>
                </div>
                <div className={styles.googleSmaller}>
                  <a href="#">
                    <GooglePlayButton />
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className={styles.caption}>
          <p>
            Made by{' '}
            <span>
              <a href="https://www.unikorns.work/">Unikorns</a>
            </span>
          </p>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
