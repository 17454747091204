import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import BlueLogotype from 'assets/icons/stages-logo-blue.inline.svg';
import WhiteLogotype from 'assets/icons/stages-logo-white.inline.svg';

import styles from './logo.module.scss';

const Logo = ({ variant }) => {
  let variantLogo;
  switch (variant) {
    case 'blue':
      variantLogo = <BlueLogotype className={styles.logo} />;
      break;
    case 'white':
      variantLogo = <WhiteLogotype className={styles.logo} />;
      break;
    default:
      variantLogo = <BlueLogotype className={styles.logo} />;
      break;
  }
  return <Link to="/">{variantLogo}</Link>;
};

Logo.propTypes = {
  variant: PropTypes.string.isRequired,
};

export default Logo;
